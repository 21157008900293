import React, { Suspense, lazy } from 'react';
import Preloader from '@comp/Preloader';
import useCustomNavigate, { recipeBaseUrl } from '@src/helpers/navigation';
import { useParams } from 'react-router';

const MenuDay = lazy(() => import('@comp/MenuDay'));

const MenuDayWrapper = () => {
  const { navigateToRecipe } = useCustomNavigate();
  const { day } = useParams();
  return (
    <Suspense fallback={<Preloader />}>
      <MenuDay
        day={day}
        onRecipeClick={id => {
          navigateToRecipe(id);
        }}
        recipeBaseUrl={recipeBaseUrl}
      />
    </Suspense>
  );
};

export default MenuDayWrapper;
