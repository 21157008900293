import { connect } from 'react-redux';
import { sitemapActions } from '@ducks/sitemap';
import Sitemap from './Sitemap';

const mapStateToProps = state => ({
  error: state.sitemap.error,
  items: state.sitemap.items
});

const mapDispatchToProps = dispatch => ({
  getSitemap: () => {
    dispatch(sitemapActions.getSitemap());
  }
});

const SitemapContainer = connect(mapStateToProps, mapDispatchToProps)(Sitemap);

export default SitemapContainer;
