/* eslint-disable consistent-return */
import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tracking from '@src/utils/helpers/tracking/tracking';
import { userActions } from '@ducks/user';
import { modalsActions } from '@ducks/modals';
import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import modals from '@src/utils/constants/modals';
import useCookieConsents from '@src/hooks/useCookieConsents';
import usePrivacySettingsIndicator from '@src/hooks/usePrivacySettingsIndicator';
import config from '@src/config';
import { disablePrivacySettings } from '@src/utils/helpers/userAgent';

const weightPlanActorData = (birthdate, male, keepWeight) => {
  const age =
    birthdate?.length === 8 &&
    moment(birthdate, 'YYYYMMDD').isValid() &&
    moment().diff(birthdate, 'years');
  let gender;
  if (male !== undefined) {
    gender = male ? 'male' : 'female';
  }
  let programType;
  if (keepWeight !== undefined) {
    programType = keepWeight ? 'Keep weight' : 'Lose weight';
  }
  return {
    gender,
    age,
    programType
  };
};

const UserState = ({
  hasSubscription,
  getUserState,
  getWeightplan,
  birthdate,
  male,
  keepWeight,
  spidId,
  getHealthDataConsent,
  productImprovementConsentShouldAsk,
  productImprovementConsentStatus,
  isLoggedIn,
  openHealthDataConsentModal,
  isMenuAdmin,
  useWsCalls,
  product
}) => {
  const cookieConsents =
    config.scc && !disablePrivacySettings
      ? useCookieConsents(
          config.scc,
          isLoggedIn === undefined ? undefined : spidId || null,
          tracking.tracker()
        )
      : null;

  if (config.psi && !disablePrivacySettings) {
    usePrivacySettingsIndicator(
      config.psi,
      spidId || undefined,
      tracking.tracker()
    );
  }

  // initial request to check user state (login, subscription)
  useEffect(() => {
    getUserState();
  }, []);

  useEffect(() => {
    if (isLoggedIn && hasSubscription) {
      getHealthDataConsent();
      const oneHourInMilliseconds = 1000 * 60 * 60;
      // const oneHourInMilliseconds = 1000 * 10;
      const intervalId = setInterval(() => {
        getHealthDataConsent();
      }, oneHourInMilliseconds);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, hasSubscription]);

  useEffect(() => {
    if (productImprovementConsentShouldAsk) {
      openHealthDataConsentModal();
    }
  }, [productImprovementConsentShouldAsk]);

  useEffect(() => {
    if (isMenuAdmin && !isWellobe(product)) {
      const [wsCalls, setWsCalls] = useWsCalls();
      setWsCalls({
        ...wsCalls(),
        getLogFromDate: wsCalls().getPlanningDay,
        deleteLoggedFoodItem: wsCalls().deletePlanningFoodItem,
        putLoggedFoodItem: wsCalls().putPlanningFoodItem,
        postLoggedFoodItem: wsCalls().postPlanningFoodItem,
        getKcal: wsCalls().getPlanningKcal
      });
    }
  }, [isMenuAdmin]);

  useEffect(() => {
    tracking.configureTrackingState(
      productImprovementConsentStatus,
      isLoggedIn,
      cookieConsents
    );
  }, [productImprovementConsentStatus, isLoggedIn, cookieConsents]);

  // initial request get actor data (spidId, age, gender, programType)
  useEffect(() => {
    if (hasSubscription) {
      getWeightplan();
    }
  }, [hasSubscription]);

  useEffect(() => {
    if (hasSubscription) {
      const trackingUser = {
        ...weightPlanActorData(birthdate, male, keepWeight),
        schibstedAccountId: spidId
      };
      tracking.setUser(trackingUser);
    }
  }, [birthdate, male, keepWeight, spidId]);

  return null;
};

UserState.propTypes = {
  getUserState: PropTypes.func,
  getWeightplan: PropTypes.func,
  hasSubscription: PropTypes.bool,
  birthdate: PropTypes.string,
  male: PropTypes.bool,
  keepWeight: PropTypes.bool,
  spidId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getUserConsent: PropTypes.func,
  openHealthDataConsentModal: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  useWsCalls: PropTypes.func,
  product: PropTypes.string,
  isMenuAdmin: PropTypes.bool,
  productImprovementConsentShouldAsk: PropTypes.bool,
  getHealthDataConsent: PropTypes.func,
  productImprovementConsentStatus: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  getUserState: () => {
    dispatch(userActions.getUserState());
  },
  getWeightplan: () => {
    dispatch(userActions.getWeightplan());
  },
  getHealthDataConsent: () => {
    dispatch(userActions.getHealthDataConsent());
  },
  openHealthDataConsentModal: () => {
    dispatch(modalsActions.showModal(modals.HEALTH_DATA_CONSENT_MODAL));
  }
});

const mapStateToProps = state => ({
  hasSubscription: state.user.hasSubscription,
  spidId: state.user.userId,
  birthdate: state.weightplan.user.birthdate,
  male: state.weightplan.user.male,
  keepWeight: state.weightplan.program.keepWeight,
  productImprovementConsentShouldAsk:
    state.user.productImprovementConsentShouldAsk,
  productImprovementConsentStatus: state.user.productImprovementConsentStatus,
  isLoggedIn: state.user.isLoggedIn,
  isMenuAdmin: state.user.isMenuAdmin,
  product: state.config.product
});

const container = connect(mapStateToProps, mapDispatchToProps)(UserState);

export default container;
