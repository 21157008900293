import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ModalRoot from '@comp/Modals/ModalRoot';
import Preloader from '@comp/Preloader';
import { logbookUrl } from '@src/helpers/navigation';
import config from '@src/config';
import paths from '../paths';

const HealthConsent = lazy(() => import('@comp/HealthConsent'));
const Logbook = lazy(() => import('./Logbook'));
const ProgramStart = lazy(() => import('./ProgramStart'));
const LandingPage = lazy(() => import('./LandingPage'));
const RecipeItem = lazy(() => import('./RecipeItem'));
const RecipeCreate = lazy(() => import('./RecipeCreate'));
const RecipeList = lazy(() => import('./RecipeList'));
const UserRecipeList = lazy(() => import('./UserRecipeList'));
const FavouriteRecipeList = lazy(() => import('./FavouriteRecipeList'));
const ProfileCurrent = lazy(() => import('./ProfileCurrent'));
const NoSubscriptionPage = lazy(() => import('./NoSubscriptionPage'));
const WelcomeToWrapper = lazy(() => import('./WelcomeToWrapper'));
const FoodstuffFormWrapper = lazy(() => import('./FoodstuffForm'));

const WeeklyMenu = lazy(() => import('./WeeklyMenu'));
const MenuOnboarding = lazy(() => import('./MenuOnboarding'));
const MenuOnboardingWelcome = lazy(() => import('./MenuOnboardingWelcome'));
const MenuOnboardingReset = lazy(() => import('./MenuOnboardingReset'));
const RecipeReport = lazy(() => import('./RecipeReport'));
const PageNotFoundPage = lazy(() => import('../shared/PageNotFoundPage'));
const ArticleSections = lazy(() => import('../shared/ArticleSections'));

const subRoot = '/';
const { app } = paths[config.product];
const AppPages = () => (
  <>
    <Routes primary={false}>
      <Route
        path="*"
        element={
          <Suspense fallback={<Preloader />}>
            <PageNotFoundPage />
          </Suspense>
        }
      />

      <Route
        path={subRoot}
        element={
          <Suspense fallback={<Preloader />}>
            <LandingPage />
          </Suspense>
        }
      />

      <Route
        path={app.logbook}
        element={
          <Suspense fallback={<Preloader />}>
            <Logbook />
          </Suspense>
        }
      />

      {!!app.training && (
        <Route
          path={app.training}
          element={
            <Suspense fallback={<Preloader />}>
              <ArticleSections sectionId={app.training} />
            </Suspense>
          }
        />
      )}

      <Route
        path={app.recipes}
        element={
          <Suspense fallback={<Preloader />}>
            <RecipeList />
          </Suspense>
        }
      />
      <Route
        path={app.userRecipes}
        element={
          <Suspense fallback={<Preloader />}>
            <UserRecipeList />
          </Suspense>
        }
      />
      <Route
        path={app.favouriteRecipes}
        element={
          <Suspense fallback={<Preloader />}>
            <FavouriteRecipeList />
          </Suspense>
        }
      />
      <Route
        path={`${app.recipes}/${app.edit}`}
        element={
          <Suspense fallback={<Preloader />}>
            <RecipeCreate />
          </Suspense>
        }
      />
      <Route
        path={`${app.recipes}/${app.edit}/:recipeId`}
        element={
          <Suspense fallback={<Preloader />}>
            <RecipeCreate />
          </Suspense>
        }
      />
      <Route
        path={`${app.recipes}/:recipeId`}
        element={
          <Suspense fallback={<Preloader />}>
            <RecipeItem />
          </Suspense>
        }
      />
      <Route
        path={`${app.foodstuff}/${app.edit}/:foodstuffId`}
        element={
          <Suspense fallback={<Preloader />}>
            <FoodstuffFormWrapper />
          </Suspense>
        }
      />
      <Route
        path={`${app.foodstuff}/${app.edit}`}
        element={
          <Suspense fallback={<Preloader />}>
            <FoodstuffFormWrapper />
          </Suspense>
        }
      />

      <Route
        path={app.healthConsent}
        element={
          <Suspense fallback={<Preloader />}>
            <HealthConsent
              onComplete={() => {
                window.location.href = logbookUrl;
              }}
            />
          </Suspense>
        }
      />
      <Route
        path={app.programStart}
        element={
          <Suspense fallback={<Preloader />}>
            <ProgramStart />
          </Suspense>
        }
      />
      <Route
        path={app.programCurrent}
        element={
          <Suspense fallback={<Preloader />}>
            <ProfileCurrent />
          </Suspense>
        }
      />
      <Route
        path={app.welcomeTo}
        element={
          <Suspense fallback={<Preloader />}>
            <WelcomeToWrapper />
          </Suspense>
        }
      />
      <Route
        path={app.menu}
        element={
          <Suspense fallback={<Preloader />}>
            <WeeklyMenu />
          </Suspense>
        }
      />
      <Route
        path={app.menuOnboarding}
        element={
          <Suspense fallback={<Preloader />}>
            <MenuOnboarding />
          </Suspense>
        }
      />
      <Route
        path={app.menuOnboardingWelcome}
        element={
          <Suspense fallback={<Preloader />}>
            <MenuOnboardingWelcome />
          </Suspense>
        }
      />
      <Route
        path={app.menuOnboardingReset}
        element={
          <Suspense fallback={<Preloader />}>
            <MenuOnboardingReset />
          </Suspense>
        }
      />

      <Route
        path={`${app.recipes}/:recipeId/${app.report}`}
        element={
          <Suspense fallback={<Preloader />}>
            <RecipeReport />
          </Suspense>
        }
      />

      <Route
        path={app.noActiveSubscription}
        element={
          <Suspense fallback={<Preloader />}>
            <NoSubscriptionPage />
          </Suspense>
        }
      />
    </Routes>
    <ModalRoot />
  </>
);

export default AppPages;
