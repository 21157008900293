import React, { Suspense, lazy } from 'react';
import Preloader from '@comp/Preloader';

const FoodstuffMerging = lazy(() => import('./FoodstuffMerging'));

const FoodstuffMergingWrapper = () => (
  <Suspense fallback={<Preloader />}>
    <FoodstuffMerging />
  </Suspense>
);

export default FoodstuffMergingWrapper;
