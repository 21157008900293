import React, { useEffect } from 'react';
import tracking from '@src/utils/helpers/tracking/tracking';
import PageNotFound from '@comp/PageNotFound';
import useCustomNavigate from '@src/helpers/navigation';

const PageNotFoundPage = () => {
  const { navigateToHomePage } = useCustomNavigate();
  useEffect(() => {
    tracking.track404SalePageView();
  }, []);

  return <PageNotFound onButtonClick={navigateToHomePage} />;
};

export default PageNotFoundPage;
