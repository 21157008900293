/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './Sitemap.scss';

const Sitemap = ({ items, getSitemap }) => {
  useEffect(() => {
    getSitemap();
  }, []);

  return (
    <div className={s.container}>
      {items.map(({ headline, name, articles }, i) => (
        <div key={i}>
          <h2 className={s.headline}>{headline || name}</h2>
          <ul className={s.list}>
            {articles.map(({ title, link }, index) => (
              <li key={index}>
                <a href={link} className={s.link}>
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

Sitemap.propTypes = {
  items: PropTypes.array,
  getSitemap: PropTypes.func
};

export default Sitemap;
