"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.pluralType = void 0;
exports.default = localize;
const sprintf_js_1 = require("sprintf-js");
const dependencies_1 = __importStar(require("@src/utils/helpers/dependencies"));
/**
 * Returns localized content string based on pre configured language.
 * @param  {String} key Loco key get localized content for
 * @param  {Array} args Contains arguments to replace string variables with.
 * @return {String} Localized content string
 */
function localize(key, args = [], count) {
    // NOTE: dependecies and keys can only be accessed at run time to work properly.
    const { i18n } = dependencies_1.default.get(dependencies_1.keys.I18N);
    const label = count ? i18n[key][count] : i18n[key];
    return (0, sprintf_js_1.vsprintf)(label, args);
}
const pluralType = (count) => (count === 1 ? 'one' : 'other');
exports.pluralType = pluralType;
