import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Preloader from '@comp/Preloader';

import withRequiresAdmin from '@comp/Admin';
import FoodstuffBulkEdit from '@comp/FoodstuffBulkEdit';
import RecipeTagsBulkEdit from '@comp/RecipeTagsBulkEdit';
import AdminHomeWrapper from './AdminHomeWrapper';
import MenuDayWrapper from './MenuDayWrapper';
import FoodstuffMergingWrapper from './FoodstuffMergingWrapper';
import config from '../../config';
import paths from '../paths';

const AdminWeeklyMenu = lazy(() => import('./AdminWeeklyMenu'));
const PageNotFoundPage = lazy(() => import('../shared/PageNotFoundPage'));

const { admin } = paths[config.product];

const AdminHomeWrapperWithRequiresAdmin = withRequiresAdmin(
  AdminHomeWrapper,
  PageNotFoundPage
);

const MenuDayWrapperWithRequiresAdmin = withRequiresAdmin(
  MenuDayWrapper,
  PageNotFoundPage
);

const FoodstuffMergingWrapperWithRequiresAdmin = withRequiresAdmin(
  FoodstuffMergingWrapper,
  PageNotFoundPage
);

const FoodstuffBulkEditWithRequiresAdmin = withRequiresAdmin(
  FoodstuffBulkEdit,
  PageNotFoundPage
);

const RecipeTagsBulkEditWithRequiresAdmin = withRequiresAdmin(
  RecipeTagsBulkEdit,
  PageNotFoundPage
);

const AdminPages = () => (
  <Routes>
    <Route
      path="*"
      element={
        <Suspense fallback={<Preloader />}>
          <PageNotFoundPage default />
        </Suspense>
      }
    />
    <Route path="/" element={<AdminHomeWrapperWithRequiresAdmin />} />
    <Route
      path={`${admin.menu}/:day`}
      element={<MenuDayWrapperWithRequiresAdmin />}
    />
    <Route
      path={`${admin.foodstuffMerging}`}
      element={<FoodstuffMergingWrapperWithRequiresAdmin />}
    />
    <Route
      path={`${admin.foodstuffBulkEdit}`}
      element={<FoodstuffBulkEditWithRequiresAdmin />}
    />
    <Route
      path={`${admin.recipeTagsBulkEdit}`}
      element={<RecipeTagsBulkEditWithRequiresAdmin />}
    />
    <Route
      path={`${admin.weeklyMenu}`}
      element={
        <Suspense fallback={<Preloader />}>
          <AdminWeeklyMenu />
        </Suspense>
      }
    />
  </Routes>
);

export default AdminPages;
