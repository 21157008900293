import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { noActiveSubUrl } from '@src/helpers/navigation';

import { loginUser } from '@src/helpers/schibsted-account';
import NoSubscriptionPage from './app/NoSubscriptionPage';

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  hasSubscription: state.user.hasSubscription
});

const PrivateRoute = ({ children, isLoggedIn, hasSubscription }) => {
  const { pathname } = useLocation();
  const isLoadingNoActiveSubUrl = pathname === noActiveSubUrl;
  let component = null;

  if (isLoggedIn && !hasSubscription) {
    component = <Navigate to={noActiveSubUrl} />;

    // The block blow is a workaround to prevent an infinite redirect loop.
    if (isLoadingNoActiveSubUrl) {
      component = <NoSubscriptionPage />;
    }
  } else if (isLoggedIn && hasSubscription) {
    component = children;

    // Prevent user from accessing "no-active-subscription-page" and redirects to home page.
    if (isLoadingNoActiveSubUrl) {
      component = <Navigate to="/" />;
    }
  } else if (isLoggedIn === false) {
    loginUser(window.location.href);
  }

  return component;
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isLoggedIn: PropTypes.bool,
  hasSubscription: PropTypes.bool
};

const container = connect(mapStateToProps)(PrivateRoute);

export default container;
