import React, { Suspense, lazy } from 'react';
import Preloader from '@comp/Preloader';
import {
  foodstuffCreateBaseUrl,
  seeMenuByAdminUrl,
  mergePendingFoodstuffByAdminUrl
} from '../../helpers/navigation';

const AdminHome = lazy(() => import('./AdminHome'));

const AdminHomeWrapper = () => (
  <Suspense fallback={<Preloader />}>
    <AdminHome
      editFoodstuffLink={foodstuffCreateBaseUrl}
      seeMenuLink={seeMenuByAdminUrl}
      mergePendingFoodstuffLink={mergePendingFoodstuffByAdminUrl}
    />
  </Suspense>
);

export default AdminHomeWrapper;
