import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { campaignActions } from '@ducks/campaign';
import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import { useEffect } from 'react';
import refTracking from '../../../helpers/refTracking';

const mapDispatchToProps = dispatch => ({
  setTrackingParams: data => {
    dispatch(campaignActions.setSubscriptionParams(data));
  }
});

const mapStateToProps = state => ({
  articleId: state.article.id,
  articleCanonicalUrl: state.article.canonicalUrl,
  location: state.router.location
});

const CampaignParams = ({
  articleId,
  setTrackingParams,
  articleCanonicalUrl,
  location
}) => {
  useEffect(() => {
    // do not include articles like 'terms of use' in conversion tracking
    const blackListedArticleIds = isWellobe ? ['0n5j5o', 'EW646G'] : [];
    const articleIsBlacklisted = blackListedArticleIds.includes(articleId);
    const trackingParams = refTracking.getCampaignParams(
      articleIsBlacklisted ? null : articleId,
      location,
      articleIsBlacklisted ? null : articleCanonicalUrl
    );
    setTrackingParams(trackingParams);
  }, [articleId, articleCanonicalUrl, location]);
  return null;
};

CampaignParams.propTypes = {
  setTrackingParams: PropTypes.func,
  articleId: PropTypes.string,
  articleCanonicalUrl: PropTypes.string,
  location: PropTypes.object
};

const container = connect(mapStateToProps, mapDispatchToProps)(CampaignParams);

export default container;
